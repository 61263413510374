import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TodoList = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');

  // Fetch tasks from the API
  useEffect(() => {
    axios.get('/api/tasks')
      .then(res => setTasks(res.data.tasks))
      .catch(err => console.error(err));
  }, []);

  // Add a new task
  const addTask = () => {
    axios.post('/api/tasks', { title: newTask })
      .then(res => setTasks([...tasks, { id: res.data.id, title: newTask, completed: 0 }]))
      .catch(err => console.error(err));
    setNewTask('');
  };

  // Update a task
  const updateTask = (id, completed) => {
    axios.put(`/api/tasks/${id}`, { completed: completed ? 0 : 1 })
      .then(() => setTasks(tasks.map(task => task.id === id ? { ...task, completed: !task.completed } : task)))
      .catch(err => console.error(err));
  };

  // Delete a task
  const deleteTask = (id) => {
    axios.delete(`/api/tasks/${id}`)
      .then(() => setTasks(tasks.filter(task => task.id !== id)))
      .catch(err => console.error(err));
  };
  console.log(tasks);
  return (
    <div>
      <h1>To-Do List</h1>
      <input
        type="text"
        value={newTask}
        onChange={(e) => setNewTask(e.target.value)}
      />
      <button onClick={addTask}>Add Task</button>
      <ul>
        {tasks.map(task => (
          <li key={task.id}>
            <span
              style={{ textDecoration: task.completed ? 'line-through' : 'none' }}
              onClick={() => updateTask(task.id, 1)}
            >
              {task.title}
            </span>
            <button onClick={() => deleteTask(task.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TodoList;
